import React from "react"
import Header from "@ecom/ui/components/HeaderNew"

import Layout from "../components/Layouts/secondaryPage"
import Success from "../components/Success"

const title = "Благодарим за уделенное время"
const subTitle = ""
const description = ""

export default function Page() {
  return (
    <Layout>
      <Header right={<div />} />
      <Success title={title} subTitle={subTitle} description={description} />
    </Layout>
  )
}
